export const skTexts = {
  cookie_text1:
    'Cookies je textový súbor, ktorý webové prehliadače používajú na identifikáciu návštevníkov a ich preferencií na webe. Súbory cookies, ktoré sú používané na našej web stránke sú tzv. technické – ide o cookies, ktoré sú nevyhnutné len pre chod stránky a môžu niesť informáciu napr. o predvolenom jazyku alebo o navigácii na webe. Personalizované, analytické a marketingové cookies naša web stránka nevyužíva a ani žiadne informácie o vás s nikým priamo nezdieľame. V prípade, že si nastavíte blokovanie zápisu cookies do vášho prehliadača, je možné, že sa web spomalí a nebudete môcť využiť kontaktný formulár na našej stránke. Informácie o využívaní cookies nájdete v dokumente ',
  cookie_link1: 'Pravidlá cookies',
  cookie_text2: ' a ',
  cookie_link2: 'Ochrana osobných údajov',
  cookie_positive: 'Súhlasím',
  cookie_negative: 'Nesúhlasím',
  headerbar_left: 'Sme tu pre vás každý deň',
  menu_reviews: 'Recenzie',
  menu_news: 'Novinky',
  menu_pricing: 'Cenník',
  menu_about: 'O nás',
  menu_contact: 'Kontakt',
  headerbanner_title1: 'Veterinárna klinika',
  headerbanner_title2: 'Poprad',
  intro_title: 'Čo od nás očakávať?',
  intro_text:
    'Veterinárna klinika Poprad poskytuje preventívnu a terapeutickú starostlivosť pre všetky druhy spoločenských zvierat (psy, mačky, vtáky, hlodavce, plazy, korytnačky) a všetky hospodárske zvieratá. O vaše zvieratá sa stará tím skúsených veterinárnych lekárov, ktorý má k dispozícii profesionálne prístrojové a laboratórne vybavenie.',
  intro_more: 'Viac o nás →',
  intro_emergency: 'POHOTOVOSŤ',
  intro_emergencyinfo: 'Pohotovosť je k dispozícii 24/7!',
  news_text: 'Všetky najčerstvejšie informácie a novinky nájdete na našom',
  news_textlink: 'Facebooku →',
  facebook_pagename: 'Veterinárna klinika Poprad',
  facebook_followers: 'sledovateľov',
  facebook_like: 'Páči sa mi to',
  facebook_share: 'Zdieľať',
  instagram_followtext: 'Sledujte nás aj na',
  instagram_followlink: 'Instagrame →',
  contact_addressheader: 'Kde nás nájdete?',
  contact_scheduleheader: 'Ordinačné hodiny',
  contact_scheduleweek: 'Po-Pia',
  contact_scheduleweekend: 'So-Ne',
  contact_schedulelink: 'Rozpis ordinačných hodín',
  contact_connectionheader: 'Spojte sa s nami',
  contact_form_name: 'Meno a priezvisko',
  contact_form_email: 'Email',
  contact_form_text: 'Text správy',
  contact_form_submit: 'Odoslať',
  devices_text: 'Sme vybavení modernými diagnostickými a chirurgickými prístrojmi',
  footer_contactheader: 'Kontakt',
  footer_addressheader: 'Adresa',
  footer_addresstext1: 'Veterinárna klinika Poprad',
  footer_scheduleheader: 'Ordinačné hodiny',
  footer_scheduleweek: 'Pondelok - Piatok',
  footer_scheduleweekend: 'Sobota - Nedeľa',
  footer_schedulelink: 'Rozpis ordinačných hodín',
  reviews_info: 'Google recenzia',
  pricing_header: 'Cenník veterinárnych služieb',
  pricing_servicespdf: 'Zoznam služieb PDF',
  pricing_pricespdf: 'Kompletný cenník služieb PDF',
  pricing_labheader: 'Vlastné laboratórium',
  pricing_labtext:
    'Pre rýchlu a spoľahlivú diagnostiku je veľmi dôležité vyšetrenie krvi, ktoré pomáha lekárovi určiť a kontrolovať ochorenia a stavy. Pomáha aj pri kontrole funkcie orgánov a účinnosti zvolenej liečby. Vlastné laboratórium nám pomáha urobiť maximum pre vášho miláčika bez čakania.',
  pricing_ortoheader: 'Ortopédia',
  pricing_ortotext:
    'Prevencia a liečebná starostlivosť o oporný a pohybový aparát zvierat. Prevencia zaisťuje správny vývoj a fungovanie oporného a pohybového systému. Liečebná starostlivosť zahŕňa medikamentóznu liečbu a operačné zákroky. Posudzovanie DBK a DLK, operácia ruptúry predného skríženého väzu TTA metódou, operácia luxácie pately, osteosyntézy zlomenín, artrodéza, operácie chrbtice, myelografické vyšetrenie a iné ortopedické zákroky...',
  team_header: 'Naším cieľom je...',
  team_text:
    '... poskytovať profesionálnu a komplexnú starostlivosť vašim miláčikom. Dôraz kladieme na prevenciu, vďaka ktorej majú zvieratá kvalitnejší, dlhší a šťastnejší život. Preto sa tím našej kliniky neustále vzdeláva a vybavenie našej kliniky modernizuje a rozširuje, aby sme každému nášmu zvieraciemu pacientovi mohli poskytnúť tú najlepšiu medicínsku starostlivosť, profesionálny a ľudský prístup.',
  team_members: [
    {
      name: 'MVDr. Bystrík Nahalka',
      about:
        'Majiteľ, odborný garant a hlavný lekár kliniky. Absolvent Fakulty veterinárneho lekárstva Veterinárnej univerzity Brno (r. 1995). Odborné zameranie: ortopédia, chirurgia, exoty, interná medicína',
      photo: 'photo076',
    },
    {
      name: 'MVDr. Denis Šlosár',
      about:
        'Absolvent Univerzity veterinárskeho lekárstva a farmácie v Košiciach (r. 2016; PhD. 2022 - Reprodukcia a gynekológia kobýl). Na našej klinike pracuje od roku 2016. Odborné zameranie: ortopédia, kardiológia, hospodárske zvieratá, exoty',
      photo: 'photo011',
    },
    {
      name: 'MVDr. Natália Bodnovičová',
      about:
        'Absolventka Univerzity veterinárskeho lekárstva a farmácie v Košiciach (r. 2019). Už počas štúdia praxovala na veterinárnej poliklinike MVDr. T. Vozára, kde aj po skončení štúdia, od roku 2019 pracovala a ostáva verná našej Veterinárnej klinike Poprad. Odborné zameranie: interná medicína',
      photo: 'photo017',
    },
    {
      name: 'MVDr. Barbora Barteková',
      about:
        'Absolventka Univerzity veterinárskeho lekárstva a farmácie v Košiciach (r. 2021). Po ukončení štúdia absolvovala zahraničnú stáž vo veterinárnej nemocnici Vetpark Brandýs (september - december 2021). Od roku 2022 pracuje na poliklinike MVDr. T. Vozára (Veterinárna klinika Poprad). Odborné zameranie: interná medicína',
      photo: 'photo023',
    },
    {
      name: 'MVDr. Tatiana Šlebodová',
      about:
        'Absolventka Univerzity veterinárskeho lekárstva a farmácie v Košiciach (r. 2022). Počas štúdia prax na veterinárnej poliklinike MVDr. T. Vozára. Od roku 2022 pracuje na poliklinike MVDr. Vozára a pokračuje na Veterinárnej klinike Poprad. Odborné zameranie: interná medicína',
      photo: 'photo032',
    },
    {
      name: 'Dr. Iryna Zubova',
      about:
        'Absolventka Veterinárnej fakulty Ústavu experimentálnej a klinickej veterinárnej medicíny v Charkove (r. 2008). Od roku 2022 pracuje na poliklinike MVDr. T. Vozára a kontinuálne pokračuje na Veterinárnej klinike Poprad. Odborné zameranie: interná medicína',
      photo: 'photo012',
    },
    {
      name: 'Zuzana Justhová',
      about:
        'Veterinárna sestra. Stredná odborná škola veterinárna Košice. Od roku 2003 pracovala ako veterinárna sestra a asistentka na poliklinike MVDr. Vozára a ostáva v našom tíme Veterinárnej kliniky Poprad.',
      photo: 'photo003',
    },
    {
      name: 'Júlia Silvajová',
      about:
        'Administratívna referentka. Euroškola - Súkromná obchodná akadémia. Od roku 2016 pracuje na poliklinike MVDr. Vozára a ostáva súčasťou tímu Veterinárnej kliniky Poprad.',
      photo: 'photo040',
    },
  ],
};

import { map } from 'lodash';

import Device001 from '../assets/devices/device001.jpg';
import Device002 from '../assets/devices/device002.jpg';
import Device003 from '../assets/devices/device003.jpg';
import Device004 from '../assets/devices/device004.jpg';
import Device005 from '../assets/devices/device005.jpg';
import Device006 from '../assets/devices/device006.jpg';
import Device007 from '../assets/devices/device007.jpg';
import Device008 from '../assets/devices/device008.jpg';
import Device009 from '../assets/devices/device009.jpg';
import Device010 from '../assets/devices/device010.jpg';
import Device011 from '../assets/devices/device011.jpg';
import Device012 from '../assets/devices/device012.jpg';
import Device013 from '../assets/devices/device013.jpg';
import Device014 from '../assets/devices/device014.jpg';
import Device015 from '../assets/devices/device015.jpg';
import Device016 from '../assets/devices/device016.jpg';
import Device017 from '../assets/devices/device017.jpg';
import Device019 from '../assets/devices/device019.jpg';

const devices = [
  {
    image: Device001,
    name: 'Veterinárne RTG',
    description: 'EcoRay ULTRA HF100',
  },
  { image: Device002, name: 'Nepriama digitalizácia', description: 'Konica Minolta – Regius 210' },
  { image: Device003, name: 'Hematologický analyzátor', description: 'IDEXX VetLab® Station' },
  { image: Device004, name: 'Močový analyzátor', description: 'IDEXX VetLab UA Analyzer' },
  {
    image: Device005,
    name: 'Analyzátor elektrolytov a krvných plynov',
    description: 'VetStat Electrolyte Blood Gas Analyzer',
  },
  { image: Device006, name: 'Koagulačný analyzátor', description: 'Coag Dx Analyzer' },
  { image: Device007, name: 'Biochemický analyzátor', description: 'Catalyst Dx Chemistry Analyzer' },
  { image: Device008, name: 'Mikroskop', description: 'ORGANIC MICROSCOPE / OPTICAL / BINOCULAR / LED' },
  {
    image: Device009,
    name: 'Ručný refraktometer',
    description: 'ANALOG REFRACTOMETER / MANUAL / FOR THE FOOD INDUSTRY / POCKET',
  },
  {
    image: Device010,
    name: 'Inhalačný prístroj s monitorom životných funkcií',
    description: 'VETNAR 1600 Kompaktní narkotizační přístroj pro inhalační anestezii zvířat od 0,1 do 100 kg.',
  },
  {
    image: Device011,
    name: 'Kyslíkový koncentrátor OXygen concentrator',
    description: 'Catalyst Dx Chemistry Analyzer',
  },
  { image: Device012, name: 'Endoskop', description: 'Storz 692360 20 veterinary video camera III.' },
  { image: Device013, name: 'Ortopedická vŕtačka', description: 'Linvatec hall power pro electric II modular' },
  { image: Device014, name: 'Prístroj na meranie vnútroočného tlaku', description: 'Tono pen vet Reichert' },
  { image: Device015, name: 'Fibroskop', description: 'fibroskop Storz' },
  { image: Device016, name: 'Dentálny ultrazvuk', description: 'Ultrazvukový odstraňovač zubného kameňa UDS-L LED' },
  { image: Device017, name: 'Videotoskop ', description: '' },
  { image: Device019, name: 'EKG', description: 'CardiMax FX-7102 ECG' },
];

export default ({ getText }) => {
  return (
    <div className="devices">
      <div className="content">
        <div className="devices-wrapper">
          {map(devices, (device, i) => (
            <div key={`dev${i}`} className="device">
              <span className="device-text">
                <b>{device.name}</b>
                <br />
                {device.description}
              </span>
              <img className="device-image" src={device.image} />
            </div>
          ))}
        </div>
        <p className="devices-text">{getText('devices_text')}</p>
      </div>
    </div>
  );
};

export const enTexts = {
  cookie_text1:
    'Cookie is a text file that web browsers use to identify visitors and their preferences on the web. The cookies used on our website are so-called technical cookies - these are cookies that are essential only for the operation of the site and may contain information, for example, about the default language or website navigation. Our website does not use personalized, analytical, and marketing cookies, and we do not share any information about you with anyone directly. If you set your browser to block the writing of cookies, the website may slow down and you may not be able to use the contact form on our site. Information about the use of cookies can be found in the document ',
  cookie_link1: 'Cookie Policy',
  cookie_text2: ' and ',
  cookie_link2: 'Privacy Policy',
  cookie_positive: 'Agree',
  cookie_negative: 'Disagree',
  headerbar_left: 'We are ready to help every day',
  menu_reviews: 'Reviews',
  menu_news: 'News',
  menu_pricing: 'Pricing',
  menu_about: 'About us',
  menu_contact: 'Contact',
  headerbanner_title1: 'Veterinary Clinic',
  headerbanner_title2: 'Poprad',
  intro_title: 'What to expect from us?',
  intro_text:
    'Veterinary clinic Poprad provides preventive and therapeutic care for all types of companion animals (dogs, cats, birds, rodents, reptiles, turtles) and all farm animals. Your animals are taken care of by a team of experienced veterinarians who have access to professional equipment and laboratory facilities.',
  intro_more: 'More about us →',
  intro_emergency: 'EMERGENCY',
  intro_emergencyinfo: 'Emergency is available 24/7!',
  news_text: 'You can find all the latest information and news on our',
  news_textlink: 'Facebook →',
  facebook_pagename: 'Veterinary Clinic Poprad',
  facebook_followers: 'followers',
  facebook_like: 'Like',
  facebook_share: 'Share',
  instagram_followtext: 'Follow us also on',
  instagram_followlink: 'Instagram →',
  contact_addressheader: 'Where to find us?',
  contact_scheduleheader: 'Office hours',
  contact_scheduleweek: 'Mon-Fri',
  contact_scheduleweekend: 'Sat-Sun',
  contact_schedulelink: 'Office hours schedule',
  contact_connectionheader: 'Get in touch with us',
  contact_form_name: 'Name and surname',
  contact_form_email: 'Email',
  contact_form_text: 'Message text',
  contact_form_submit: 'Send',
  devices_text: 'We are equipped with modern diagnostic and surgical instruments',
  footer_contactheader: 'Contact',
  footer_addressheader: 'Address',
  footer_addresstext1: 'Veterinary Clinic Poprad',
  footer_scheduleheader: 'Office hours',
  footer_scheduleweek: 'Monday - Friday',
  footer_scheduleweekend: 'Saturday - Sunday',
  footer_schedulelink: 'Office hours schedule',
  reviews_info: 'Google review',
  pricing_header: 'Price list of veterinary services',
  pricing_servicespdf: 'List of services PDF',
  pricing_pricespdf: 'Complete price list of services PDF',
  pricing_labheader: 'Laboratory',
  pricing_labtext:
    'For quick and reliable diagnostics, it is very important to examine blood, which helps the doctor to determine and monitor diseases and conditions. It also helps in assessing the function of organs and the effectiveness of the chosen treatment. Our own laboratory helps us to do the maximum for your pet without waiting.',
  pricing_ortoheader: 'Orthopedics',
  pricing_ortotext:
    'Prevention and therapeutic care of the musculoskeletal system of animals. Prevention ensures the proper development and functioning of the musculoskeletal system. Assessment of cranial cruciate ligament and caudal cruciate ligament, TTA method for ruptured anterior cruciate ligament, patellar luxation surgery, fracture osteosynthesis, arthrodesis, spinal surgeries, myelographic examination, and other orthopedic procedures...',
  team_header: 'Our goal is...',
  team_text:
    '... to provide professional and comprehensive care for your pets. We emphasize prevention, which allows animals to have a higher quality, longer, and happier life. Therefore, our clinic team constantly educates and modernizes and expands the equipment of our clinic, so that we can provide the best medical care, professional and human approach to each of our animal patients.',
  team_members: [
    {
      name: 'MVDr. Bystrík Nahalka',
      about:
        'Owner, professional guarantor, and chief physician of the clinic. Graduate of the Faculty of Veterinary Medicine, University of Veterinary Medicine Brno (1995). Specialization: orthopedics, surgery, exotics, internal medicine',
      photo: 'photo076',
    },
    {
      name: 'MVDr. Denis Šlosár',
      about:
        'Graduate of the University of Veterinary Medicine and Pharmacy in Košice (2016; PhD. 2022 - Reproduction and Gynecology of Mares). He has been working at our clinic since 2016. Specialization: orthopedics, cardiology, farm animals, exotics',
      photo: 'photo011',
    },
    {
      name: 'MVDr. Natália Bodnovičová',
      about:
        'Graduate of the University of Veterinary Medicine and Pharmacy in Košice (2019). She practiced at the MVDr. T. Vozára veterinary polyclinic during her studies, where she has been working since 2019 and remains loyal to our Veterinary clinic Poprad. Specialization: internal medicine',
      photo: 'photo017',
    },
    {
      name: 'MVDr. Barbora Barteková',
      about:
        'Graduate of the University of Veterinary Medicine and Pharmacy in Košice (2021). After completing her studies, she completed a foreign internship at the Vetpark Brandýs veterinary hospital. She has been working at the MVDr. T. Vozára veterinary polyclinic since 2022. Specialization: internal medicine',
      photo: 'photo023',
    },
    {
      name: 'MVDr. Tatiana Šlebodová',
      about:
        'Graduate of the University of Veterinary Medicine and Pharmacy in Košice (2022). During her studies, she practiced at the MVDr. T. Vozára veterinary polyclinic. She has been working at the MVDr. Vozára polyclinic since 2022 and continues at the Vetrinary clinic Poprad. Specialization: internal medicine',
      photo: 'photo032',
    },
    {
      name: 'MVDr. Iryna Zubova',
      about:
        'Graduate of the Faculty of Veterinary Medicine, Institute of Experimental and Clinical Veterinary Medicine in Kharkiv (2008). She has been working at the MVDr. T. Vozára polyclinic since 2022 and continues at the Veterinary clinic Poprad. Specialization: internal medicine',
      photo: 'photo012',
    },
    {
      name: 'Zuzana Justhová',
      about:
        'Veterinary nurse. Veterinary high school Košice. She has been working as a veterinary nurse and assistant at the MVDr. Vozára polyclinic for a long time and remains part of our veterinary clinic team.',
      photo: 'photo003',
    },
    {
      name: 'Júlia Silvajová',
      about:
        'Administrative clerk. Euroschool - Private Business Academy. She has been working at the MVDr. Vozára polyclinic since 2016 and remains part of the veterinary clinic team.',
      photo: 'photo040',
    },
  ],
};
